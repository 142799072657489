import { Card, Checkbox, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ModuleChoice } from './module-form-no-school'
import { renderModulePrice } from './get-module-price-string'
import { Institution, ModulePrice } from '../../../../types'
const { Paragraph, Text } = Typography

type Props = {
  module: ModuleChoice
  onChange: (e: CheckboxChangeEvent) => void
  institution: Institution
  price: ModulePrice
  slashed?: boolean
}

const SelectableModuleCard = ({ module, onChange, institution, price, slashed }: Props) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e)
  }

  const pricesVisible = !institution.features.hidePricesInRegistration

  return (
    <label htmlFor={module.id}>
      <Card
        size="small"
        title={
          <div
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {module.name}
          </div>
        }
        extra={
          <Checkbox checked={module.chosen} style={{ marginLeft: '0.5em' }} id={module.id} onChange={handleChange} />
        }
        style={{
          cursor: 'pointer',
          boxShadow: module.chosen ? 'inset 0 0 3em var(--main-accent-color)' : 'none',
        }}
      >
        <Paragraph>
          {module.startTime} - {module.endTime}
        </Paragraph>
        <Paragraph>{module.description}</Paragraph>
        {pricesVisible && !!price && <Text delete={slashed}>{renderModulePrice(price)}</Text>}
      </Card>
    </label>
  )
}

export default SelectableModuleCard
