import { SmileOutlined } from '@ant-design/icons'
import { Trans, t } from '@lingui/macro'
import { Button, Card, Modal, Result, Row, Typography, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { toDateString } from '../../helper/format-date'
import weekdays from '../../lib/weekdays'
import { useGuardianContext } from '../../routes/institution/guardian/guardian-context'
import { useInstitutionContext } from '../../routes/institution/institution-context'
import { Contract, Institution, Module, ModuleMutation, ModuleMutationType, School } from '../../types'
import { Field } from '../form/field'
import { LocalizedDatePicker } from '../localized-date-picker'
import SelectableModuleList from './selectable-module-list'
import { useAdditionalBookingPrices } from '../child/child-form/module-form/use-module-prices'
import { getActiveContract } from '../../helper/get-active-contract'
import { dateString } from '../../helper/date-string'
import { sortBy } from 'remeda'

const { Title, Text } = Typography

type Props = {
  contracts: Contract[]
  schools: School[]
}

const BookAdditionalModuleButton = ({ contracts, schools }: Props) => {
  const [moduleBookingModalOpen, setModuleBookingModalOpen] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const guardian = useGuardianContext((state) => state.guardian)
  const navigate = useNavigate()
  const [selectedModuleIds, setSelectedModuleIds] = useState<string[]>([])
  const [message, setMessage] = useState('')

  const initialDate = getInitialDate(contracts)

  const lastContractEnd = sortBy(contracts, [(c) => c.startDate, 'desc'])[0]?.endDate

  const [startDate, setStartDate] = useState<string>(initialDate)
  const [endDate, setEndDate] = useState<string>(initialDate)
  const contract = getActiveContract(contracts, startDate)
  const [loading, setLoading] = useState(false)
  const { institution } = useInstitutionContext()

  const currentLocation = institution?.locations.find((location) => location.id === contract.locationId)

  const modules = currentLocation?.modules || []
  const school = schools.find((school) => school.id === contract.schoolId)
  const modulesToShow = modules
    .filter(excludeBySchool(school))
    .filter(excludeModulesNotInContractAgeGroup({ contract, institution: institution! }))
    .filter(excludeBookedModules(contract, endDate))

  const filteredSelection = useMemo(() => {
    return selectedModuleIds
      .map((choice) => ({
        moduleId: choice,
        ageGroupId: contract.ageGroupId,
      }))
  }, [selectedModuleIds, contract.ageGroupId])

  const prices = useAdditionalBookingPrices({
    institutionId: institution!.id,
    ageGroupId: contract.ageGroupId,
    selection: filteredSelection,
    contractId: contract.id,
  }).prices

  const packagePriceNum = useAdditionalBookingPrices({
    institutionId: institution!.id,
    ageGroupId: contract.ageGroupId,
    selection: filteredSelection,
    contractId: contract.id,
  }).packagePrice

  const compileModuleBookingBody = (): Omit<ModuleMutation, 'approvalState' | 'reason'>[] => {
    let result: Omit<ModuleMutation, 'approvalState' | 'reason'>[] = []
    if (isReadyToSubmit()) {
      for (
        let currentDate = dayjs(startDate);
        !currentDate.isAfter(dayjs(endDate));
        currentDate = currentDate.add(1, 'day')
      ) {
        const currentWeekdayValue = currentDate.weekday()
        const currentWeekday = weekdays.find((weekday) => weekday.value === currentWeekdayValue)
        const moduleMutationsForCurrentDay: Omit<ModuleMutation, 'approvalState' | 'reason'>[] = []

        selectedModuleIds.forEach((id) => {
          const module = modules.find((m) => m.id === id)

          if (
            module &&
            currentWeekday &&
            weekdays.find((weekday) => weekday.name === module.weekday)?.value === currentWeekday.value
          ) {
            moduleMutationsForCurrentDay.push({
              id: nanoid(),
              createdAt: new Date(),
              contractId: contract.id,
              moduleId: id,
              comment: message,
              effectiveAt: currentDate.format('YYYY-MM-DD'),
              mutationType: 'additionalBooking' as ModuleMutationType,
            })
          }
        })

        result = [...result, ...moduleMutationsForCurrentDay]
      }
    } else {
      throw new Error('module Cancellation incomplete before submit')
    }
    return result
  }

  const onSubmitBookAdditionalModule = async () => {
    setLoading(true)
    try {
      const body = JSON.stringify(compileModuleBookingBody())

      await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/module-mutation?contractId=${contract.id}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
          },
          body,
        }
      )

      setMessage('')
      setSelectedModuleIds([])
      setShowResult(true)
    } finally {
      setLoading(false)
    }
  }

  const onCloseResult = () => {
    setModuleBookingModalOpen(false)
    if (guardian) {
      navigate(`/${guardian.institutionId}/guardian/${guardian.id}/bookings`)
    }
  }

  const isReadyToSubmit = (): boolean => {
    return selectedModuleIds.length > 0
  }

  const getClosingDaysForDateRange = (): { name: string; date: string }[] | undefined => {
    if (currentLocation?.closingDays === undefined) {
      return undefined
    }

    const closingDaysInDateRange = currentLocation?.closingDays
      .filter(
        (closingDay) =>
          (dayjs(closingDay).isBefore(endDate) || dayjs(closingDay).isSame(endDate)) &&
          (dayjs(closingDay).isAfter(startDate) || dayjs(closingDay).isSame(startDate))
      )
      .map((closingDay) => {
        const wd = dayjs(closingDay).weekday()
        const currentWeekday = weekdays.find((weekday) => weekday.value === wd)?.title || ''
        return { name: currentWeekday, date: closingDay }
      })

    return closingDaysInDateRange
  }

  const hasClosingDaysInDateRange = () => {
    const closingDays = getClosingDaysForDateRange()
    return closingDays !== undefined && closingDays.length > 0
  }

  return (
    <>
      <Button
        style={{ flex: '1' }}
        onClick={() => {
          setModuleBookingModalOpen(true)
        }}
      >
        {t({ message: 'Zusatzbuchung' })}
      </Button>
      <Modal open={moduleBookingModalOpen} footer={null} onCancel={() => setModuleBookingModalOpen(false)}>
        {!showResult && (
          <>
            <Row>
              <Title level={3}>{t({ message: 'Zusätzlich buchen' })}</Title>
            </Row>
            <Row style={{ marginBottom: '1em' }}>
              <Card size="small" style={{ width: '100%', boxShadow: 'inset 0 0 1em var(--main-accent-color)' }}>
                <Text>
                  {t({
                    message:
                      'Die Institutionsleitung kann den zusätzlichen Tag je nach Kapazitäten annehmen oder ablehnen.',
                  })}
                </Text>
              </Card>
            </Row>
            <Field label={t({ message: 'Datum' })}>
              <LocalizedDatePicker
                value={startDate}
                onlyAllow="future"
                onChange={(value: string) => {
                  if (value) {
                    setStartDate(value)
                    if (value > endDate) {
                      setEndDate(value)
                    }
                  }
                }}
                max={lastContractEnd}
              />
            </Field>
            <Field label={t({ message: 'Bis' })}>
              <LocalizedDatePicker
                value={endDate}
                onlyAllow="future"
                onChange={(value: string) => {
                  if (value) {
                    setEndDate(value)
                    if (value < startDate) {
                      setStartDate(value)
                    }
                  }
                }}
                max={lastContractEnd}
              />
            </Field>
            {hasClosingDaysInDateRange() && (
              <Card size="small" style={{ marginTop: '1em', width: '100%', border: '1px solid red' }}>
                <Trans>
                  Die gewählte Zeitspanne enthält Schliesstage. Die Institution {institution?.name} ist an diesem Tag
                  nicht geöffnet.
                </Trans>
                <ul>
                  {getClosingDaysForDateRange()?.map((closingDay) => (
                    <li key={closingDay.date}>
                      {closingDay.name}, {toDateString(new Date(closingDay.date))}
                    </li>
                  ))}
                </ul>
              </Card>
            )}
            <SelectableModuleList
              startDate={startDate}
              endDate={endDate}
              selectedModuleIds={selectedModuleIds}
              setSelectedModuleIds={setSelectedModuleIds}
              modules={modulesToShow}
              showPrices={true}
              prices={prices}
              slashPrices={(packagePriceNum !== null)}
            />
            {packagePriceNum !== null && (
              <Space style={{ width: '100%', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                <Text strong>Subtotal:</Text>
                <Text>{packagePriceNum} CHF</Text>
              </Space>
            )}
            <Field label={t({ message: 'Notiz' })}>
              <TextArea
                style={{ width: '100%' }}
                placeholder={t({ message: 'Sie müssen eine Notiz hinterlegen um die Buchung abzuschliessen.' })}
                autoSize={{ minRows: 4, maxRows: 6 }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Field>
            <Row>
              <Button
                disabled={!isReadyToSubmit()}
                loading={loading}
                onClick={onSubmitBookAdditionalModule}
                type="primary"
                style={{ marginTop: '1em', width: '100%' }}
              >
                {t({ message: 'Speichern' })}
              </Button>
            </Row>
          </>
        )}
        {/* 
        Den Buchungsstatus können Sie unter "Zusatzbuchungen" verfolgen ansehen.        
        */}
        {showResult && (
          <Result
            icon={<SmileOutlined style={{ color: 'var(--main-accent-color)' }} />}
            title={
              <div style={{ marginTop: '2em' }}>
                <Title level={4}>{t({ message: 'Die Buchung wurde provisorisch erstellt.' })}</Title>
                <Text>
                  {t({
                    message: 'Den Buchungsstatus können Sie unter Zusatzbuchungen verfolgen.',
                  })}
                </Text>
              </div>
            }
            extra={
              <Button style={{ marginTop: '1em', width: '100%' }} type="primary" onClick={onCloseResult}>
                {t({ message: 'Weiter' })}
              </Button>
            }
          />
        )}
      </Modal>
    </>
  )
}

export default BookAdditionalModuleButton

const getInitialDate = (contracts: Contract[]) => {
  const contract = getActiveContract(contracts)
  const contractStartDateString = dateString(contract.startDate)
  const todayDateString = dateString(new Date())
  return todayDateString < contractStartDateString ? contractStartDateString : todayDateString
}

const excludeBySchool = (school: School | undefined) => (mdoule: Module) => {
  if (school === undefined) {
    return true
  } else {
    return school.moduleIds.includes(mdoule.id)
  }
}

const excludeBookedModules = (contract: Contract, endDate: string) => (module: Module) => {
  if (endDate < dayjs(contract.startDate).format('YYYY-MM-DD')) return true
  if (!contract.bookedModules.find((booking) => module.id === booking.moduleId)) {
    return true
  }
  return false
}

const excludeModulesNotInContractAgeGroup =
  ({ contract, institution }: { contract: Contract; institution: Institution }) =>
  (module: Module) => {
    if (!institution.restrictModulesByContractAgeGroup) return true
    return module.ageGroups.some((ageGroup) => ageGroup.ageGroupId === contract.ageGroupId)
  }
