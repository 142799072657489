import { Col, Row, Space, Typography, Divider } from 'antd'
import { moduleCompareString } from '../../../../lib/module-compare-string'
import weekdays from '../../../../lib/weekdays'
import { Location, ModulePrice } from '../../../../types'
import { ModuleChoice } from './module-form-with-school'
import SelectableModuleCardWithSchool from './selectable-module-card-with-school'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { SpecialWednesdayHuenenberg } from './special-wednesday-huenenberg'
const { Title, Text } = Typography

type Props = {
  primaryLocation: Location
  moduleChoices: ModuleChoice[]
  onModuleChange: (
    moduleId: string,
    checked: boolean,
    ageGroupeId: string | undefined,
    comingFromGoingTo: { comingFrom?: string; comingFromTime?: string; goingTo?: string; goingToTime?: string },
  ) => void
  prices: Array<{ moduleId: string; price: ModulePrice }>
  packagePrices: Array<{ amount: number; includedModuleIds: string[]; type: 'MONTHLY' | 'PER_VISIT' }>
}

const ModuleBookingViewAllLocations = ({
  primaryLocation,
  moduleChoices,
  onModuleChange,
  prices,
  packagePrices,
}: Props) => {
  const { institution } = useInstitutionContext()
  const subsidiesHuenenberg = institution?.features.subsidiesHuenenberg ? true : false

  const moduleChoicesByWeekday = (weekday: string, moduleChoice: ModuleChoice[]): ModuleChoice[] =>
    moduleChoice.filter((module) => module.weekday === weekday)

  return (
    <Row gutter={16}>
      {weekdays.filter(hasModules(moduleChoices)).map((weekday) => {
        const weekdayModules = moduleChoicesByWeekday(weekday.name, moduleChoices)
        const subtotal = packagePrices.find((pkg) =>
          pkg.type === prices[0].price?.type &&
          pkg.includedModuleIds.some((moduleId) =>
            weekdayModules.map((module) => module.id).includes(moduleId)
          )
        )
        return (
          <Col flex={1} className="weekday" key={weekday.name}>
            <Row
              style={{
                height: '100%',
                flexWrap: 'nowrap',
                paddingBlock: '0.5em',
              }}
            >
              <Space
                direction="vertical"
                key={weekday.name}
                style={{
                  flex: 'auto',
                  flexWrap: 'nowrap',
                  height: '100%',
                  paddingBlock: '0.5em',
                }}
              >
                <Title level={3}>{weekday.title}</Title>

                {weekday.name === 'wednesday' && subsidiesHuenenberg ? (
                  <SpecialWednesdayHuenenberg
                    moduleChoices={weekdayModules.sort((a, b) =>
                      moduleCompareString(a).localeCompare(moduleCompareString(b))
                    )}
                    primaryLocation={primaryLocation}
                    onModuleChange={onModuleChange}
                  />
                ) : (
                  <>
                    {weekdayModules
                      .sort((a, b) => moduleCompareString(a).localeCompare(moduleCompareString(b)))
                      .map((module) => {
                        const modulePrice = prices.find((price) => price.moduleId === module.id)?.price ?? null
                        const isInPackage = packagePrices.some((pkg) =>
                          pkg.includedModuleIds.includes(module.id)
                        )
                        return (
                          <SelectableModuleCardWithSchool
                            primaryLocation={primaryLocation}
                            module={module}
                            key={module.id}
                            onChange={onModuleChange}
                            price={modulePrice}
                            slashed={isInPackage}
                          />
                        )
                      })}
                    {subtotal && (
                      <div>
                        <Divider />
                        <Text strong>Subtotal: {subtotal.amount.toFixed(2)} CHF</Text>
                      </div>
                    )}
                  </>
                )}
              </Space>
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}

export default ModuleBookingViewAllLocations

const hasModules =
  (moduleChoices: ModuleChoice[]) =>
    (weekday: { title: string; name: string }): boolean => {
      return moduleChoices.some((mc) => mc.weekday === weekday.name && mc.id)
    }
