import { Checkbox } from 'antd'
import { useState, useEffect, Fragment } from 'react'
import { Module } from '../../types'
import SelectableModule from './selectable-module'

type Props = {
  setSelectedModuleIds: (selectedModuleIds: string[]) => void
  selectedModuleIds: string[]
  modules: Module[]
  day: { title: string; name: string }
  showPrices: boolean
  prices: Array<{ moduleId: string; price: number | null }>
  slashPrices?: boolean
}

const SelectableModuleDay = ({ modules, day, setSelectedModuleIds, selectedModuleIds, showPrices, prices, slashPrices }: Props) => {
  const modulesByWeekday = modules.filter((m) => m.weekday === day.name)
  const [entireDaySelected, setEntireDaySelected] = useState<boolean>(false)

  const handleOnClickEntireDay = (value: boolean) => {
    if (value === true) {
      onSelectEntireDay()
    } else {
      onDeselectEntireDay()
    }
    setEntireDaySelected(!entireDaySelected)
  }

  const onSelectEntireDay = () => {
    onDeselectEntireDay()
    let result: string[] = []
    modulesByWeekday.forEach((m) => {
      result.push(m.id)
    })
    setSelectedModuleIds([...selectedModuleIds, ...result])
  }

  const onDeselectEntireDay = () => {
    const selectedModuleIdsCopy = selectedModuleIds
    modulesByWeekday.forEach((module) => {
      const foundIndex = selectedModuleIds.findIndex((id) => module.id === id)
      if (foundIndex !== -1) {
        selectedModuleIdsCopy.splice(foundIndex, 1)
      }
    })
    setSelectedModuleIds([...selectedModuleIdsCopy])
  }

  useEffect(() => {
    let entireDaySelected = true
    modulesByWeekday.forEach((module) => {
      if (!selectedModuleIds.find((id) => module.id === id)) {
        entireDaySelected = false
      }
    })
    setEntireDaySelected(entireDaySelected)
  }, [modulesByWeekday, selectedModuleIds])

  return (
    <Fragment>
      {modulesByWeekday.length > 0 ? (
        <li>
          <Checkbox checked={entireDaySelected} onChange={() => handleOnClickEntireDay(!entireDaySelected)}>
            {day.title}
          </Checkbox>
          <ul style={{ listStyle: 'none', paddingLeft: '1em' }}>
            {modulesByWeekday
              .sort((a, b) => +a.startTime.split(':')[0] - +b.startTime.split(':')[0])
              .map((module) => (
                <SelectableModule
                  showPrices={showPrices}
                  price={prices.find((modulePrice) => modulePrice.moduleId === module.id)?.price ?? null}
                  key={module.id}
                  module={module}
                  onChange={setSelectedModuleIds}
                  selectedModuleIds={selectedModuleIds}
                  slashPrices={slashPrices && selectedModuleIds.includes(module.id)}
                />
              ))}
          </ul>
        </li>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default SelectableModuleDay
