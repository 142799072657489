import { t } from '@lingui/macro'
import { Button, Modal, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { Guardian, Institution } from '../types'
import { Typography } from 'antd'
const { Title } = Typography

type Props = {
  childId: string
  guardian: Guardian
  institution: Institution
  onMessageSent: () => void
}

const NewChildMessageButton = ({ childId, institution, guardian, onMessageSent }: Props) => {
  const [childNoteModalOpen, setChildModalOpen] = useState(false)
  const [message, setMessage] = useState('')

  const onSubmitNote = async () => {
    const body = JSON.stringify({
      id: nanoid(),
      senderRole: 'Guardian',
      senderId: guardian.id,
      childId: childId,
      dateTime: new Date(),
      message,
    })

    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/child-message?childId=${childId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )

    setMessage('')
    setChildModalOpen(false)
    onMessageSent()
  }

  return (
    <>
      <Button
        style={{ flex: '1' }}
        onClick={() => {
          setChildModalOpen(true)
        }}
      >
        {t({ message: 'Nachricht senden' })}
      </Button>
      <Modal open={childNoteModalOpen} footer={null} onCancel={() => setChildModalOpen(false)}>
        <Row>
          <Title level={3}>{t({ message: 'Neue Nachricht erstellen' })}</Title>
        </Row>
        <Row>
          <TextArea autoSize={{ minRows: 4 }} value={message} onChange={(e) => setMessage(e.target.value)} />
        </Row>
        <Row>
          <Button
            disabled={message === ''}
            onClick={onSubmitNote}
            type="primary"
            style={{ marginTop: '1em', width: '100%' }}
          >
            {t({ message: 'Nachricht absenden' })}
          </Button>
        </Row>
      </Modal>
    </>
  )
}

export default NewChildMessageButton
