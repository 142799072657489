import { Card, Col, Divider, Row, Space, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import weekdays from '../../../../lib/weekdays'
import { ModuleChoice } from './module-form-no-school'
import SelectableModuleCard from './selectable-module-card'
import { Institution, ModulePrice } from '../../../../types'

const { Title, Text } = Typography

type Props = {
  ageGroupId?: string
  moduleChoices: ModuleChoice[]
  onModuleChange: (e: CheckboxChangeEvent) => void
  institution: Institution
  prices: Array<{ moduleId: string; price: ModulePrice }>
  packagePrices: Array<{ amount: number; includedModuleIds: string[]; type: 'MONTHLY' | 'PER_VISIT' }>
}

const ModuleAgeGroupWeekForm = ({
  moduleChoices,
  onModuleChange,
  ageGroupId,
  institution,
  prices,
  packagePrices,
}: Props) => {

  const availableModules = moduleChoices.filter(
    (mc) => ageGroupId !== undefined && mc.ageGroups.some((ag) => ag.ageGroupId === ageGroupId)
  )
  const moduleChoicesByWeekday = (weekday: string, moduleChoice: ModuleChoice[]): ModuleChoice[] =>
    moduleChoice.filter((module) => module.weekday === weekday)
  const calculateSubtotal = (weekdayModules: ModuleChoice[]): string | null => {
    const subtotal = packagePrices.find((pkg) =>
      pkg.type === prices[0].price?.type &&
      pkg.includedModuleIds.some((moduleId) =>
        weekdayModules.map((module) => module.id).includes(moduleId),
      ),
    )
    return subtotal ? subtotal.amount.toFixed(2) : null
  }

  return (
    <Card style={{ minWidth: 'fit-content', maxWidth: '100%' }}>
      <Row gutter={16}>
        {weekdays.filter(hasModules(availableModules)).map((weekday) => {
          const weekdayModules = moduleChoicesByWeekday(weekday.name, availableModules)
          const subtotal = calculateSubtotal(weekdayModules)

          return (
            <Col flex={1} className="weekday" key={weekday.name}>
              <Row
                style={{
                  height: '100%',
                  flexWrap: 'nowrap',
                  paddingBlock: '0.5em',
                }}
              >
                <Divider type="vertical" className="divider" />
                <Space
                  direction="vertical"
                  key={weekday.name}
                  style={{
                    flex: 'auto',
                    flexWrap: 'nowrap',
                    height: '100%',
                    paddingBlock: '0.5em',
                  }}
                >
                  <Title level={3}>{weekday.title}</Title>

                  {weekdayModules
                    .sort(
                      (a, b) =>
                        +a.startTime.split(':')[0] - +b.startTime.split(':')[0] ||
                        +a.startTime.split(':')[1] - +b.startTime.split(':')[1] ||
                        a.name.localeCompare(b.name),
                    )
                    .map((module, i) => {
                      const isInPackage = packagePrices.some((pkg) =>
                        pkg.includedModuleIds.includes(module.id)
                      )
                      return (
                        <SelectableModuleCard
                        module={module}
                        key={module.id}
                        onChange={onModuleChange}
                        institution={institution}
                        price={prices.find((modulePrice) => modulePrice.moduleId === module.id)?.price ?? null}
                        slashed={isInPackage}                        
                      />
                      )
                    })}

                  {subtotal && (
                    <div>
                      <Divider />
                      <Text strong>Subtotal: {subtotal} CHF</Text>
                    </div>
                  )}
                </Space>
              </Row>
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

export default ModuleAgeGroupWeekForm

const hasModules =
  (moduleChoices: ModuleChoice[]) =>
    (weekday: { title: string; name: string }): boolean => {
      return moduleChoices.some((mc) => mc.weekday === weekday.name && mc.id)
    }
