import { Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import formatChf from '../../helper/format-chf'
import { Module } from '../../types'

type Props = {
  module: Module
  onChange: (selectedModuleIds: string[]) => void
  selectedModuleIds: string[]
  showPrices: boolean
  price: number | null
  slashPrices?: boolean
}

const SelectableModule = ({ module, onChange, selectedModuleIds, showPrices, price, slashPrices }: Props) => {
  const time = `${module.startTime} - ${module.endTime}`
  const formattedPrice = price && showPrices ? formatChf(price) : ''
  const priceText = slashPrices && formattedPrice ? <del>{formattedPrice}</del> : formattedPrice

  const [selected, setSelected] = useState(
    selectedModuleIds.find((id) => module.id === id) ? true : false
  )

  const onChangeHandler = (value: boolean) => {
    if (value) {
      onChange([...selectedModuleIds, module.id])
    } else {
      const foundIndex = selectedModuleIds.findIndex((id) => module.id === id)
      const arrayCopy = selectedModuleIds
      arrayCopy.splice(foundIndex, 1)
      onChange([...arrayCopy])
    }
  }

  useEffect(() => {
    setSelected(selectedModuleIds.find((id) => module.id === id) ? true : false)
  }, [module.id, selectedModuleIds])

  return (
    <li>
      <Checkbox checked={selected} onChange={() => onChangeHandler(!selected)}>
        <span>
          {time} {module.name}
          {priceText && (
            <>
              {' - '}
              {priceText}
            </>
          )}
        </span>
      </Checkbox>
    </li>
  )
}

export default SelectableModule