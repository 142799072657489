import { useEffect, useState } from 'react'
import { ModulePrice } from '../../../../types'

export const useModulePrices = ({
  institutionId,
  locationId,
  ageGroupId,
  selection,
}: {
  institutionId: string
  locationId: string
  ageGroupId: string
  selection: Array<{ moduleId: string; ageGroupId: string }>
}) => {
  const [data, setData] = useState<{
    prices: Array<{ moduleId: string; price: ModulePrice }>
    packagePrices: Array<{ amount: number; includedModuleIds: string[]; type: 'MONTHLY' | 'PER_VISIT' }>
  }>({
    prices: [],
    packagePrices: [],
  })

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/registration/module/get-module-prices-with-packages`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ locationId, ageGroupId, selection }),
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        setData({
          prices: responseData.prices || [],
          packagePrices: responseData.packagePrices || null,
        })
      })
      .catch((error) => {
        console.error('Failed to fetch module prices:', error)
      })
  }, [institutionId, locationId, ageGroupId, selection])

  return data
}

export const useAdditionalBookingPrices = ({
  institutionId,
  contractId,
  ageGroupId,
  selection,
}: {
  institutionId: string
  contractId: string
  ageGroupId: string
  selection: Array<{ moduleId: string; ageGroupId: string }>
}) => {
  const [data, setData] = useState<{
    prices: Array<{ moduleId: string; price: number }>
    packagePrice: number | null
  }>({
    prices: [],
    packagePrice: null,
  })
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/registration/module/get-additional-booking-prices-with-subtotal`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contractId, ageGroupId, selection }),
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        setData({
          prices: responseData.prices || [],
          packagePrice: responseData.packagePrice || null,
        })
      })
      .catch((error) => {
        console.error('Failed to fetch module prices:', error)
      })
  }, [institutionId, contractId, ageGroupId, selection])
  return data
}
