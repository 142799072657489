import { Trans } from '@lingui/macro'
import { Card } from 'antd'
import { toDateTimeString } from '../../helper/format-date'
import { ChildMessage } from '../../types'
import styles from './message-card.module.css'
import mime from 'mime'

const MessageCard = ({
  message,
  highlight,
  familyId,
  institutionId,
}: {
  message: ChildMessage
  highlight?: boolean
  familyId: string
  institutionId: string
}) => {
  return (
    <Card
      size="small"
      key={message.id}
      style={{
        width: '100%',
        marginBottom: '0.5em',
        background: highlight ? '#adedd2' : 'white',
      }}
      title={
        <div
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            {message.senderRole === 'Guardian' ? (
              <Trans>Gesendete Nachricht</Trans>
            ) : (
              <Trans>Nachricht von Institution</Trans>
            )}
          </div>
          <div>{toDateTimeString(new Date(message.dateTime))}</div>
        </div>
      }
    >
      <div className={styles.cardBody}>
        <span style={{ whiteSpace: 'pre-wrap' }}>{message.message}</span>
        {message.files.map((file) => {
          const downloadLink = `${process.env.REACT_APP_LEOBA_SERVER}/api/common/${institutionId}/message-files?childId=${message.childId}&familyId=${familyId}&id=${file.id}`
          const src =
            mime.getType(file.name) === 'application/pdf'
              ? `${process.env.REACT_APP_LEOBA_SERVER}/pdfType.png`
              : downloadLink
          return (
            <img
              key={file.id}
              onClick={() => window.open(downloadLink, '_blank')}
              className={styles.cardBodyImage}
              src={src}
              alt={file.name}
            />
          )
        })}
      </div>
    </Card>
  )
}

export default MessageCard
