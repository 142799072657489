import { BellFilled } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Badge, Button, Popover, Row, Spin, Tabs, TabsProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FormPage from '../../../components/form/form-page'
import BookAdditionalModuleButton from '../../../components/guardian-page/book-additional-module-button'
import BookHolidayCareButton from '../../../components/guardian-page/book-holiday-care-button'
import ExpandableInfoList from '../../../components/guardian-page/expandable-info-list'
import LogbookCard from '../../../components/guardian-page/logbook-card'
import MessageCard from '../../../components/guardian-page/message-card'
import ModuleCancellationButton from '../../../components/guardian-page/module-cancellation-button'
import { ChildSwitcher } from '../../../components/guardian/child-switcher'
import NewChildMessageButton from '../../../components/new-child-message-button'
import { getNewSchoolYearTasks } from '../../../domain/family/new-school-year-tasks'
import { getActiveContract, getActiveContractOrUndefined } from '../../../helper/get-active-contract'
import { Child, ChildLogbook, ChildMessage, Institution } from '../../../types'
import { useInstitutionContext } from '../institution-context'
import { useGuardianContext } from './guardian-context'
import styles from './guardian-home-page.module.css'
const { Text } = Typography

const GuardianHomePage = () => {
  const { guardian, currentChild, newMessageId, family } = useGuardianContext()
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()

  const [selectedChildLogbook, setSelectedChildLogbook] = useState<ChildLogbook[]>([])
  const [selectChildMessages, setSelectedChildMessages] = useState<ChildMessage[]>([])
  const [showAdditionalBookingPopover, setShowAdditionalBookingPopover] = useState(false)
  const [showCancellationPopover, setShowCancellationPopover] = useState(false)
  const [showMessagesPopover, setShowMessagesPopover] = useState(false)

  useEffect(() => {
    const getLogbook = async (child: Child) => {
      if (guardian) {
        const response = await fetch(
          `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${guardian.institutionId}/guardian/child-logbook?childId=${child.id}`,
          {
            headers: { accept: 'application/json' },
          }
        )
        if (response.ok) {
          setSelectedChildLogbook((await response.json()) as ChildLogbook[])
        }
      }
    }

    const getMessages = async (child: Child) => {
      if (institution) {
        const messages = await fetchMessages(institution, child)
        setSelectedChildMessages(messages)
      }
    }
    if (currentChild) {
      getMessages(currentChild)
      if (institution?.dailyEventsVisibleToParents) {
        //Only fetch logbook if the institution has enabled the feature in settings
        getLogbook(currentChild)
      }
    }
  }, [guardian, currentChild, newMessageId, institution])

  const handleOnMessageSent = async () => {
    if (institution && currentChild) {
      const messages = await fetchMessages(institution, currentChild)
      setSelectedChildMessages(messages)
    }
  }

  if (!currentChild || !institution || !family) return <Spin />

  const newSchoolYearTasks = getNewSchoolYearTasks(family, institution?.registrationConfig)

  const handleOnNextSchoolYear = () => {
    if (guardian && institution) {
      navigate(`/${institution.id}/guardian/${guardian.id}/next-school-year`)
    }
  }

  const getInfoItems = (): TabsProps['items'] => {
    const infoItems = [
      {
        key: '1',
        label: t({ message: 'Aktuelle Nachrichten' }),
        children: (
          <ExpandableInfoList
            data={selectChildMessages}
            dateTimeKey={'dateTime'}
            mapFunction={(message: ChildMessage) => (
              <MessageCard
                key={message.id}
                highlight={
                  message.id === newMessageId && dayjs(message.dateTime).isAfter(dayjs().subtract(12, 'hours'))
                }
                message={message}
                familyId={currentChild.familyId}
                institutionId={institution.id}
              />
            )}
            noDataText={t({ message: 'Es sind noch keine Nachrichten vorhanden.' })}
          />
        ),
      },
    ]

    if (institution.dailyEventsVisibleToParents) {
      infoItems.push({
        key: '2',
        label: t({ message: 'Tagebuch' }),
        children: (
          <ExpandableInfoList
            data={selectedChildLogbook}
            dateTimeKey={'dateTime'}
            mapFunction={(log) => (
              <LogbookCard key={log.id} childLogbook={log} institutionId={institution.id} familyId={family.id} />
            )}
            noDataText={t({ message: 'Es gibt noch keine Tagebuch-Einträge.' })}
          />
        ),
      })
    }

    return infoItems
  }

  const activeContract = getActiveContractOrUndefined(currentChild.contracts)
  const canChangeBookings =
    activeContract !== undefined && !currentChild.isOutOfTown && activeContract.state === 'accepted'
  const hasAcceptedContract = activeContract?.state === 'accepted'

  return (
    <FormPage title={''}>
      <>
        {newSchoolYearTasks.total > 0 && (
          <Row>
            <Badge className={styles.badge} count={newSchoolYearTasks.total}>
              <Button
                className={styles.button}
                type="primary"
                icon={<BellFilled className={styles.shakeBell} />}
                onClick={handleOnNextSchoolYear}
              >{t`Anmeldung für nächstes Schuljahr`}</Button>
            </Badge>
          </Row>
        )}

        <Row>
          <ChildSwitcher />
        </Row>

        <Row style={{ paddingBottom: '1em' }}>
          {institution.holidayCare.length > 0 && activeContract && (
            <BookHolidayCareButton contract={getActiveContract(currentChild.contracts)} />
          )}
        </Row>

        <Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap-reverse',
              width: '100%',
              gap: '1em',
            }}
          >
            {canChangeBookings ? (
              <BookAdditionalModuleButton contracts={currentChild.contracts} schools={institution.schools} />
            ) : (
              <Popover
                content={
                  <Text>
                    {t({
                      message: `Module können nur zusätzlich gebucht werden, wenn bereits regelmässige Module gebucht wurden und der Vertrag akzeptiert ist.`,
                    })}
                  </Text>
                }
                trigger="click"
                placement="bottom"
                open={showAdditionalBookingPopover}
                onOpenChange={() => {
                  if (showAdditionalBookingPopover) {
                    setShowAdditionalBookingPopover(false)
                  } else {
                    setTimeout(() => setShowAdditionalBookingPopover(false), 4000)
                  }
                }}
              >
                <Button style={{ flex: '1' }} onClick={() => setShowAdditionalBookingPopover(true)}>
                  {t({ message: `Zusatzbuchung` })}
                </Button>
              </Popover>
            )}
            {canChangeBookings ? (
              <ModuleCancellationButton
                institution={institution}
                contract={getActiveContract(currentChild.contracts)}
              />
            ) : (
              <Popover
                content={
                  <Text>
                    {t({
                      message: `Module können nur abgemeldet werden, wenn regelmässige Module gebucht wurden und der Vertrag akzeptiert ist.`,
                    })}
                  </Text>
                }
                trigger="click"
                placement="bottom"
                open={showCancellationPopover}
                onOpenChange={() => {
                  if (showCancellationPopover) {
                    setShowCancellationPopover(false)
                  } else {
                    setTimeout(() => setShowCancellationPopover(false), 4000)
                  }
                }}
              >
                <Button style={{ flex: '1' }} onClick={() => setShowCancellationPopover(true)}>
                  {t({ message: `Modul abmelden` })}
                </Button>
              </Popover>
            )}
            {guardian &&
              (hasAcceptedContract ? (
                <NewChildMessageButton
                  childId={currentChild.id}
                  institution={institution}
                  guardian={guardian}
                  onMessageSent={handleOnMessageSent}
                />
              ) : (
                <Popover
                  content={
                    <Text>{t({ message: `Ihr Vertrag wurde noch nicht akzeptiert oder wurde abgelehnt.` })}</Text>
                  }
                  trigger="click"
                  placement="bottom"
                  open={showMessagesPopover}
                  onOpenChange={() => {
                    if (showMessagesPopover) {
                      setShowMessagesPopover(false)
                    } else {
                      setTimeout(() => setShowMessagesPopover(false), 4000)
                    }
                  }}
                >
                  <Button style={{ flex: '1' }} onClick={() => setShowMessagesPopover(true)}>
                    {t({ message: `Nachricht senden` })}
                  </Button>
                </Popover>
              ))}
          </div>
        </Row>

        <Tabs defaultActiveKey="1" items={getInfoItems()} size="large" tabBarStyle={{ marginTop: '1em' }} />
      </>
    </FormPage>
  )
}

const fetchMessages = async (institution: Institution, child: Child) => {
  const response = await fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/child-message?childId=${child.id}`,
    {
      headers: { accept: 'application/json' },
    }
  )
  if (response.ok) {
    return (await response.json()) as ChildMessage[]
  } else {
    return []
  }
}

export default GuardianHomePage
