import { t } from '@lingui/macro'
import { Button, Card, Checkbox, Input, Select, Typography } from 'antd'
import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { Location, ModulePrice } from '../../../../types'
import { TimeStringInput } from '../../../form/time-string-input'
import { renderModulePrice } from './get-module-price-string'
import { ModuleChoice } from './module-form-with-school'
import styles from './selectable-module-card-with-school.module.css'

const { Paragraph, Text } = Typography

type Props = {
  primaryLocation: Location
  module: ModuleChoice
  onChange: (
    moduleId: string,
    checked: boolean,
    ageGroupId: string | undefined,
    comingFromGoingTo: { comingFrom?: string; comingFromTime?: string; goingTo?: string; goingToTime?: string }
  ) => void
  price: ModulePrice
  slashed?: boolean
}

const SelectableModuleCardWithSchool = ({ primaryLocation, module, onChange, price, slashed }: Props) => {
  const [state, setState] = useState<'normal' | 'notPrimaryLocation'>('normal')
  const [ageGroupId, setAgeGroupId] = useState(module.ageGroup?.id)
  const { institution } = useInstitutionContext()
  const showPrice = institution?.features.hidePricesInRegistration ? false : true
  const askForLocationBeforeAndAfterMandatory =
    institution?.registrationConfig.askForLocationBeforeAndAfterModule === 'mandatory'
  const askForLocationBeforeAndAfterOptional =
    institution?.registrationConfig.askForLocationBeforeAndAfterModule === 'optional'
  const askForLocationBeforeAndAfter = askForLocationBeforeAndAfterMandatory || askForLocationBeforeAndAfterOptional
  const hideTimeComingFrom = institution?.features.subsidiesHuenenberg || false
  const hideTimeGoingTo = (institution?.features.subsidiesHuenenberg && module.name !== 'Modul D') || false
  const goingToTimeMin = institution?.features.subsidiesHuenenberg ? '16:30' : module.startTime
  const selectableTimeIntervalForLocationBeforeAndAfterModule =
    institution?.registrationConfig.selectableTimeIntervalForLocationBeforeAndAfterModule || 5

  const noPrimaryLocationWarnText = institution?.features.subsidiesHuenenberg
    ? t({
        message: `Beachten Sie, dass dieses Modul in ${module.location.name} stattfindet. Sie müssen selber sicherstellen, dass Ihr Kind sicher dort ankommt.`,
      })
    : t({ message: `Beachten Sie, dass dieses Modul in ${module.location.name} stattfindet.` })
  const noPrimaryLocationConfirmText = institution?.features.subsidiesHuenenberg
    ? t({ message: `Einverstanden` })
    : t({ message: `OK` })
  const groupText = institution?.features.buchrainPricing ? t({ message: `Häufigkeit` }) : t({ message: `Gruppe` })

  const handleChange = (checked: boolean) => {
    if (checked) {
      if (primaryLocation.id !== module.location.id) {
        setState('notPrimaryLocation')
      } else {
        onChange(module.id, checked, module.ageGroup?.id, {
          comingFrom: module.comingFrom,
          comingFromTime: module.comingFromTime,
          goingTo: module.goingTo,
          goingToTime: module.goingToTime,
        })
      }
    } else {
      onChange(module.id, checked, module.ageGroup?.id, {
        comingFrom: module.comingFrom,
        comingFromTime: module.comingFromTime,
        goingTo: module.goingTo,
        goingToTime: module.goingToTime,
      })
    }
  }

  useEffect(() => {
    setAgeGroupId(module.ageGroup?.id)
  }, [module.ageGroup])

  const handleOnAgeGroupClicked: MouseEventHandler = (e) => {
    e.preventDefault()
  }

  const handleOnAgeGroupChanged = (ageGroupId: string) => {
    setAgeGroupId(ageGroupId)
    if (primaryLocation.id !== module.location.id) {
      setState('notPrimaryLocation')
    } else {
      onChange(module.id, true, ageGroupId, {
        comingFrom: module.comingFrom,
        comingFromTime: module.comingFromTime,
        goingTo: module.goingTo,
        goingToTime: module.goingToTime,
      })
      setState('normal')
    }
  }

  const handlePrimaryLocationOK: MouseEventHandler = (e) => {
    e.preventDefault()
    onChange(module.id, true, ageGroupId, {
      comingFrom: module.comingFrom,
      comingFromTime: module.comingFromTime,
      goingTo: module.goingTo,
      goingToTime: module.goingToTime,
    })
    setState('normal')
  }
  const handlePrimaryLocationAbort: MouseEventHandler = (e) => {
    e.preventDefault()
    setState('normal')
  }
  const handleOnChangeBeforeModule: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(module.id, true, ageGroupId, {
      comingFrom: e.target.value,
      comingFromTime: module.comingFromTime,
      goingTo: module.goingTo,
      goingToTime: module.goingToTime,
    })
  }
  const handleOnChangeBeforeModuleTime = (newTime: string | null) => {
    onChange(module.id, true, ageGroupId, {
      comingFrom: module.comingFrom,
      comingFromTime: newTime || undefined,
      goingTo: module.goingTo,
      goingToTime: module.goingToTime,
    })
  }
  const handleOnChangeAfterModule: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(module.id, true, ageGroupId, {
      comingFrom: module.comingFrom,
      comingFromTime: module.comingFromTime,
      goingTo: e.target.value,
      goingToTime: module.goingToTime,
    })
  }
  const handleOnChangeAfterModuleTime = (newTime: string | null) => {
    onChange(module.id, true, ageGroupId, {
      comingFrom: module.comingFrom,
      comingFromTime: module.comingFromTime,
      goingTo: module.goingTo,
      goingToTime: newTime || undefined,
    })
  }

  const hasMoreThanOneLocation = institution?.locations && institution.locations.length > 1
  const highlightLocation = primaryLocation.id !== module.location.id

  return (
    <Card
      size="small"
      title={
        <label htmlFor={module.id}>
          <div
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {module.name}
          </div>
        </label>
      }
      extra={
        <Checkbox
          checked={module.chosen}
          style={{ marginLeft: '0.5em' }}
          id={module.id}
          onChange={(e) => {
            handleChange(e.target.checked)
          }}
        />
      }
      style={{
        cursor: 'pointer',
        boxShadow: module.chosen ? 'inset 0 0 3em var(--main-accent-color)' : 'none',
      }}
    >
      {state === 'notPrimaryLocation' && (
        <div>
          <Paragraph strong style={{ color: 'red' }}>
            {noPrimaryLocationWarnText}
          </Paragraph>
          <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '1em' }}>
            <Button type="primary" onClick={handlePrimaryLocationOK}>
              {noPrimaryLocationConfirmText}
            </Button>
            <Button onClick={handlePrimaryLocationAbort}>{t({ message: `Abbrechen` })}</Button>
          </div>
        </div>
      )}
      {state === 'normal' && (
        <>
          {hasMoreThanOneLocation && highlightLocation && (
            <Paragraph onClick={() => handleChange(!module.chosen)} strong style={{ color: 'red' }}>
              {t({ message: `Standort` }) + ' ' + module.location.name}
            </Paragraph>
          )}
          {hasMoreThanOneLocation && !highlightLocation && (
            <Paragraph onClick={() => handleChange(!module.chosen)}>
              {t({ message: `Standort` }) + ' ' + module.location.name}
            </Paragraph>
          )}

          <Paragraph onClick={() => handleChange(!module.chosen)}>
            {module.startTime} - {module.endTime}
          </Paragraph>

          {module.chosen && (
            <Paragraph>
              <label>{groupText}</label>
              <Select
                onClick={handleOnAgeGroupClicked}
                onChange={handleOnAgeGroupChanged}
                style={{ width: '100%', maxWidth: '205px', borderColor: !module.ageGroup?.id ? 'red' : undefined }}
                className={module.ageGroup?.id ? undefined : styles.error}
                value={module.ageGroup?.id}
                data-testid="age-group-select"
              >
                {module.location.ageGroups
                  .filter((ageGroup) => module.ageGroups.some((ag) => ag.ageGroupId === ageGroup.id))
                  .filter((ageGroup) => ageGroup.bookableFromGuardians)
                  .map((ageGroup) => (
                    <Select.Option key={ageGroup.id}>{ageGroup.name}</Select.Option>
                  ))}
              </Select>
            </Paragraph>
          )}
          {module.chosen && askForLocationBeforeAndAfter && (
            <Paragraph>
              <label>Woher kommt Ihr Kind vor diesem Modul?</label>
              <div className={styles.comingFromGoingToContainer}>
                {!hideTimeComingFrom && (
                  <TimeStringInput
                    min={module.startTime}
                    max={module.endTime}
                    step={selectableTimeIntervalForLocationBeforeAndAfterModule}
                    onChange={handleOnChangeBeforeModuleTime}
                    value={module.comingFromTime || ''}
                    className={
                      !module.comingFromTime && askForLocationBeforeAndAfterMandatory ? styles.error : undefined
                    }
                  />
                )}
                <Input.TextArea
                  value={module.comingFrom}
                  aria-label="before"
                  autoSize
                  onChange={handleOnChangeBeforeModule}
                  placeholder={t({ message: `z.B. Musikschule` })}
                  className={!module.comingFrom && askForLocationBeforeAndAfterMandatory ? styles.error : undefined}
                />
              </div>
            </Paragraph>
          )}
          {module.chosen && askForLocationBeforeAndAfter && (
            <Paragraph>
              <label>Wohin geht Ihr Kind nach diesem Modul?</label>
              <div className={styles.comingFromGoingToContainer}>
                {!hideTimeGoingTo && (
                  <TimeStringInput
                    min={goingToTimeMin}
                    max={module.endTime}
                    step={selectableTimeIntervalForLocationBeforeAndAfterModule}
                    onChange={handleOnChangeAfterModuleTime}
                    value={module.goingToTime || ''}
                    className={!module.goingToTime && askForLocationBeforeAndAfterMandatory ? styles.error : undefined}
                  />
                )}
                <Input.TextArea
                  value={module.goingTo}
                  aria-label="after"
                  autoSize
                  onChange={handleOnChangeAfterModule}
                  className={!module.goingTo && askForLocationBeforeAndAfterMandatory ? styles.error : undefined}
                  placeholder={t({ message: `z.B. Volleyball` })}
                />
              </div>
            </Paragraph>
          )}
          <Paragraph style={{ fontWeight: 'bold' }}>{module.description}</Paragraph>
          {showPrice && (
            <Text delete={slashed}>
              {renderModulePrice(price)}
            </Text>
          )}
        </>
      )}
    </Card>
  )
}

export default SelectableModuleCardWithSchool
